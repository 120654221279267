<template>
  <button
    type="button"
    @click.prevent="clicked"
    class="button"
    :class="[
      {
        'button--active': active,
        'button--confirming': confirming,
        [`button--${type}`]: type
      }
    ]"
    :style="
      `
      margin: ${buttonMargin};
      padding: ${buttonPadding};
      width: ${buttonWidth};
      height: ${buttonHeight};
    `
    "
  >
    <span class="button__slot"><slot /></span>

    <i v-if="icon" class="button__icon" :class="`icon-${icon}`"></i>

    <!-- Confirmation message -->
    <div
      v-if="confirmationMessage.text"
      class="button__confirmation-message"
      :style="`color: ${confirmationMessage.color || ''}`"
    >
      {{ confirmationMessage.text }}
    </div>
    <Tooltip
      v-if="tooltip.active"
      :tooltipPadding="tooltip.padding"
      :tooltipWidth="tooltip.width"
      :tooltipHeight="tooltip.height"
      ref="tooltip"
    />
  </button>
</template>

<script>
// Store data
import { mapGetters } from 'vuex'

// Services
import createTooltip from '@/services/tooltip'

// Components
import Tooltip from '@/components/Tooltip'

export default {
  name: 'Button',

  props: {
    active: {
      type: Boolean,
      required: false
    },

    icon: {
      type: String,
      required: false
    },

    buttonMargin: {
      type: String,
      required: false
    },

    buttonPadding: {
      type: String,
      required: false
    },

    buttonWidth: {
      type: String,
      required: false
    },

    buttonHeight: {
      type: String,
      required: false
    },

    type: {
      type: String,
      required: false
    },

    confirming: {
      type: Boolean,
      required: false
    },

    tooltip: {
      type: Object,
      default: () => ({
        active: false,
        settings: {}
      }),
      required: false
    },

    confirmationMessage: {
      type: Object,
      default: () => ({
        text: '',
        color: 'inherit'
      }),
      required: false
    },

    submit: {
      type: Boolean,
      required: false
    }
  },

  components: {
    Tooltip
  },

  watch: {
    getAppLanguage() {
      if (this.tooltip.active)
        createTooltip(this.$el, this.$refs.tooltip.$el, this.tooltip.settings)

      return this.getAppLanguage
    }
  },

  computed: {
    ...mapGetters(['getAppLanguage'])
  },

  mounted() {
    if (this.tooltip.active)
      createTooltip(this.$el, this.$refs.tooltip.$el, this.tooltip.settings)

    if (this.submit) this.$el.setAttribute('type', 'submit')
  },

  methods: {
    /**
     *
     */
    clicked() {
      this.$emit('button-clicked')
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  --button-height-default: var(--size-unit-5);
  --button-padding-default: var(--size-unit-2);
  --button-outline-default: 1px solid transparent;
  --button-outline-active: 1px solid var(--color-main-light);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: var(--button-height-default);
  border-radius: var(--radius-default);
  border: var(--button-outline-default);
  padding: 0 var(--button-padding-default);
  font-size: var(--font-size-s);
  background-color: var(--color-main);
  color: var(--color-light);
  transition: all var(--transition-default);
  cursor: pointer;

  &:hover {
    background-color: rgba(var(--color-main-rgb), var(--opacity-hover));

    .button__slot,
    .button__icon {
      opacity: var(--opacity-hover);
    }
  }

  &:disabled {
    opacity: var(--opacity-disabled);
    cursor: not-allowed;
  }

  &--success {
    background-color: var(--color-success);
    border-color: var(--color-success);
    color: var(--color-brand-darkest);

    &:hover {
      background-color: rgba(var(--color-success-rbg), var(--opacity-hover));
      border-color: rgba(var(--color-success-rbg), var(--opacity-hover));
    }
  }

  &--warning {
    background-color: var(--color-warning);
    border-color: var(--color-warning);
    color: var(--color-brand-darkest);

    &:hover {
      background-color: rgba(var(--color-warning-rgb), var(--opacity-hover));
      border-color: rgba(var(--color-warning-rgb), var(--opacity-hover));
    }
  }

  &--error {
    background-color: var(--color-error);
    border-color: var(--color-error);
    color: var(--color-brand-lightest);
    font-weight: var(--font-weight-semi-bold);

    &:hover {
      background-color: rgba(var(--color-error-rgb), var(--opacity-hover));
      border-color: rgba(var(--color-error-rgb), var(--opacity-hover));
    }
  }

  &--secondary {
    background-color: var(--color-theme-lighter);
    color: var(--color-dark);

    &:hover {
      background-color: var(--color-theme-light);
    }
  }

  &--tertiary {
    background-color: var(--color-theme-lightest);
    color: var(--color-dark);

    &:hover {
      background-color: var(--color-theme-lighter);
    }
  }

  &--quaternary {
    background-color: var(--color-theme-lightest);
    color: var(--color-dark);

    &:hover {
      background-color: var(--color-theme-light);
    }
  }

  &--custom {
    background-color: var(--color-theme-lightest);
    color: var(--color-dark);

    &:hover {
      background-color: var(--color-theme-lighter);
    }

    &:disabled {
      &:hover {
        background-color: var(--color-theme-lightest);
      }
    }
  }

  &[class*='active'],
  &--active,
  &:active,
  &:focus {
    outline: 0;
    border: var(--button-outline-active);
  }

  &--confirming {
    border: 1px solid var(--color-success) !important;
  }

  &--app {
    background-color: var(--color-theme-lighter);
    color: var(--color-dark);
    border-radius: var(--radius-accent);

    &-secondary {
      background-color: var(--color-theme-lighter);
      color: var(--color-dark);
      border-radius: var(--radius-accent);

      &:hover {
        color: var(--color-light);
      }

      @include screen-large {
        background-color: var(--color-theme-lightest);
      }
    }

    &-active {
      background-color: var(--color-accent);
      color: var(--color-brand-darkest);
      border-radius: var(--radius-accent);
      border: none !important;

      &::before {
        content: '';
        position: absolute;
        border-radius: var(--size-unit-large);
        width: calc(100% + var(--size-unit));
        height: calc(100% + var(--size-unit));
        background-color: transparent;
        border: var(--size-unit-half-2) solid var(--color-main);
      }

      &:hover {
        background-color: var(--color-accent);
        color: var(--color-brand-darkest);
      }
    }

    &:hover {
      color: var(--color-light);
    }
  }
}

.button__slot {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  transition: opacity var(--transition-default);

  &::first-letter {
    text-transform: capitalize;
  }
}

.button__icon {
  margin-left: var(--size-unit);
}

.button__confirmation-message {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--font-size-xs);

  @include screen-medium {
    display: initial;
  }
}
</style>
