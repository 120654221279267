import AWS from 'aws-sdk'

const credentials = new AWS.Credentials({
  accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
  sessionToken: null
})

const region = process.env.VUE_APP_AWS_REGION

const translate = new AWS.Translate({
  region,
  credentials
})

export default translate
