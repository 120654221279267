const translationLanguages = [
  {
    language: 'Afrikaans',
    variation: '',
    code: 'af',
    prefix: '(AF)'
  },

  {
    language: 'Albanian',
    variation: '',
    code: 'sq',
    prefix: '(SQ)'
  },

  {
    language: 'Amharic',
    variation: '',
    code: 'am',
    prefix: '(AM)'
  },

  {
    language: 'Arabic',
    variation: '',
    code: 'ar',
    prefix: '(AR)'
  },

  {
    language: 'Armenian',
    variation: '',
    code: 'hy',
    prefix: '(HY)'
  },

  {
    language: 'Azerbaijani',
    variation: '',
    code: 'az',
    prefix: '(AZ)'
  },

  {
    language: 'Bengali',
    variation: '',
    code: 'bn',
    prefix: '(BN)'
  },

  {
    language: 'Bosnian',
    variation: '',
    code: 'bs',
    prefix: '(BS)'
  },

  {
    language: 'Bulgarian',
    variation: '',
    code: 'bg',
    prefix: '(BG)'
  },

  {
    language: 'Catalan',
    variation: '',
    code: 'ca',
    prefix: '(CA)'
  },

  {
    language: 'Chinese',
    variation: 'Simplified',
    code: 'zh',
    prefix: '(ZH)'
  },

  {
    language: 'Chinese',
    variation: 'Traditional',
    code: 'zh-TW',
    prefix: '(TW)'
  },

  {
    language: 'Croatian',
    variation: '',
    code: 'hr',
    prefix: '(HR)'
  },

  {
    language: 'Czech',
    variation: '',
    code: 'cs',
    prefix: '(CS)'
  },

  {
    language: 'Danish',
    variation: '',
    code: 'da',
    prefix: '(DA)'
  },

  {
    language: 'Dari',
    variation: '',
    code: 'fa-AF',
    prefix: '(AF)'
  },

  {
    language: 'Dutch',
    variation: '',
    code: 'nl',
    prefix: '(NL)'
  },

  {
    language: 'English',
    variation: '',
    code: 'en',
    prefix: '(EN)'
  },

  {
    language: 'Estonian',
    variation: '',
    code: 'et',
    prefix: '(ET)'
  },

  {
    language: 'Farsi',
    variation: 'Persian',
    code: 'fa',
    prefix: '(FA)'
  },

  {
    language: 'Filipino Tagalog',
    variation: '',
    code: 'tl',
    prefix: '(TL)'
  },

  {
    language: 'Finnish',
    variation: '',
    code: 'fi',
    prefix: '(FI)'
  },

  {
    language: 'French',
    variation: '',
    code: 'fr',
    prefix: '(FR)'
  },

  {
    language: 'French',
    variation: 'Canadian',
    code: 'fr-CA',
    prefix: '(CA)'
  },

  {
    language: 'Georgian',
    variation: '',
    code: 'ka',
    prefix: '(KA)'
  },

  {
    language: 'German',
    variation: '',
    code: 'de',
    prefix: '(DE)'
  },

  {
    language: 'Greek',
    variation: '',
    code: 'el',
    prefix: '(EL)'
  },

  {
    language: 'Gujarati',
    variation: '',
    code: 'gu',
    prefix: '(GU)'
  },

  {
    language: 'Haitian Creole',
    variation: '',
    code: 'ht',
    prefix: '(HT)'
  },

  {
    language: 'Hausa',
    variation: '',
    code: 'ha',
    prefix: '(HA)'
  },

  {
    language: 'Hebrew',
    variation: '',
    code: 'he',
    prefix: '(HE)'
  },

  {
    language: 'Hindi',
    variation: '',
    code: 'hi',
    prefix: '(HI)'
  },

  {
    language: 'Hungarian',
    variation: '',
    code: 'hu',
    prefix: '(HU)'
  },

  {
    language: 'Icelandic',
    variation: '',
    code: 'is',
    prefix: '(IS)'
  },

  {
    language: 'Indonesian',
    variation: '',
    code: 'id',
    prefix: '(ID)'
  },

  {
    language: 'Italian',
    variation: '',
    code: 'it',
    prefix: '(IT)'
  },

  {
    language: 'Japanese',
    variation: '',
    code: 'ja',
    prefix: '(JA)'
  },

  {
    language: 'Kannada',
    variation: '',
    code: 'kn',
    prefix: '(KN)'
  },

  {
    language: 'Kazakh',
    variation: '',
    code: 'kk',
    prefix: '(KK)'
  },

  {
    language: 'Korean',
    variation: '',
    code: 'ko',
    prefix: '(KO)'
  },

  {
    language: 'Latvian',
    variation: '',
    code: 'lv',
    prefix: '(LV)'
  },

  {
    language: 'Lithuanian',
    variation: '',
    code: 'lt',
    prefix: '(LT)'
  },

  {
    language: 'Macedonian',
    variation: '',
    code: 'mk',
    prefix: '(MK)'
  },

  {
    language: 'Malay',
    variation: '',
    code: 'ms',
    prefix: '(MS)'
  },

  {
    language: 'Malayalam',
    variation: '',
    code: 'ml',
    prefix: '(ML)'
  },

  {
    language: 'Maltese',
    variation: '',
    code: 'mt',
    prefix: '(MT)'
  },

  {
    language: 'Mongolian',
    variation: '',
    code: 'mn',
    prefix: '(MN)'
  },

  {
    language: 'Norwegian',
    variation: '',
    code: 'no',
    prefix: '(NO)'
  },

  {
    language: 'Persian',
    variation: '',
    code: 'fa',
    prefix: '(FA)'
  },

  {
    language: 'Pashto',
    variation: '',
    code: 'ps',
    prefix: '(PS)'
  },

  {
    language: 'Polish',
    variation: '',
    code: 'pl',
    prefix: '(PL)'
  },

  {
    language: 'Portuguese',
    variation: '',
    code: 'pt',
    prefix: '(PT)'
  },

  {
    language: 'Romanian',
    variation: '',
    code: 'ro',
    prefix: '(RO)'
  },

  {
    language: 'Russian',
    variation: '',
    code: 'ru',
    prefix: '(RU)'
  },

  {
    language: 'Serbian',
    variation: '',
    code: 'sr',
    prefix: '(SR)'
  },

  {
    language: 'Sinhala',
    variation: '',
    code: 'si',
    prefix: '(SI)'
  },

  {
    language: 'Slovak',
    variation: '',
    code: 'sk',
    prefix: '(SK)'
  },

  {
    language: 'Slovenian',
    variation: '',
    code: 'sl',
    prefix: '(SL)'
  },

  {
    language: 'Somali',
    variation: '',
    code: 'so',
    prefix: '(SO)'
  },

  {
    language: 'Spanish',
    variation: '',
    code: 'es',
    prefix: '(ES)'
  },

  {
    language: 'Spanish',
    variation: 'Mexican',
    code: 'es-MX',
    prefix: '(MX)'
  },

  {
    language: 'Swahili',
    variation: '',
    code: 'sw',
    prefix: '(SW)'
  },

  {
    language: 'Swedish',
    variation: '',
    code: 'sv',
    prefix: '(SV)'
  },

  {
    language: 'Tagalog',
    variation: '',
    code: 'tl',
    prefix: '(TL)'
  },

  {
    language: 'Tamil',
    variation: '',
    code: 'ta',
    prefix: '(TA)'
  },

  {
    language: 'Telugu',
    variation: '',
    code: 'te',
    prefix: '(TE)'
  },

  {
    language: 'Thai',
    variation: '',
    code: 'th',
    prefix: '(TH)'
  },

  {
    language: 'Turkish',
    variation: '',
    code: 'tr',
    prefix: '(TR)'
  },

  {
    language: 'Ukrainian',
    variation: '',
    code: 'uk',
    prefix: '(UK)'
  },

  {
    language: 'Urdu',
    variation: '',
    code: 'ur',
    prefix: '(UR)'
  },

  {
    language: 'Uzbek',
    variation: '',
    code: 'uz',
    prefix: '(UZ)'
  },

  {
    language: 'Vietnamese',
    variation: '',
    code: 'vi',
    prefix: '(VI)'
  },

  {
    language: 'Welsh',
    variation: '',
    code: 'cy',
    prefix: '(CY)'
  }
]

export { translationLanguages }
