import firebase from 'firebase/app'

// Firebase auth module
import 'firebase/auth'

// Firebase firestore module
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/functions'

// Helpers
import { isDevelopmentMode, isProductionMode } from './../helpers/environment'

// Firebase app config for development environment
let config = {}

if (isDevelopmentMode) {
  config = {
    apiKey: process.env.VUE_APP_DEV_API_KEY,
    authDomain: process.env.VUE_APP_DEV_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_DEV_DATABASE_URL,
    projectId: process.env.VUE_APP_DEV_PROJECT_ID,
    storageBucket: process.env.VUE_APP_DEV_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_DEV_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_DEV_APP_ID,
    measurementId: process.env.VUE_APP_DEV_MEASUREMENT_ID
  }
}

if (isProductionMode) {
  // Firebase app config for alpha environment
  config.apiKey = process.env.VUE_APP_API_KEY
  config.authDomain = process.env.VUE_APP_AUTH_DOMAIN
  config.databaseURL = process.env.VUE_APP_DATABASE_URL
  config.projectId = process.env.VUE_APP_PROJECT_ID
  config.storageBucket = process.env.VUE_APP_STORAGE_BUCKET
  config.messagingSenderId = process.env.VUE_APP_MESSAGING_SENDER_ID
  config.appId = process.env.VUE_APP_APP_ID
  config.measurementId = process.env.VUE_APP_MEASUREMENT_ID
}

// Init our firebase app
firebase.initializeApp(config)
firebase.analytics()

export default firebase
