<template>
  <Notification
    v-show="notification.state && notification.message !== ''"
    :message="notification.message"
    :type="notification.type"
    hideCloseButton
    autoWidth
  />
</template>

<script>
// Components
import Notification from '@/components/Notification'
import { mapActions } from 'vuex'

export default {
  name: 'SaveNotification',

  data: () => ({
    notification: {
      state: false,
      message: ''
    }
  }),

  components: {
    Notification
  },

  watch: {
    notification() {
      return this.notification
    }
  },

  mounted() {
    this.initialize()
  },

  methods: {
    ...mapActions(['resetNotification']),

    /*
     *
     */
    initialize() {
      document.addEventListener('keydown', event => {
        if (
          (window.navigator.platform.match('Mac')
            ? event.metaKey
            : event.ctrlKey) &&
          event.keyCode === 83
        ) {
          event.preventDefault()
          // Process the event here (such as click on submit button)
          this.notification = {
            state: true,
            message: `${this.$companyName} autosaves your work!`
          }

          setTimeout(() => {
            this.notification = {
              state: false,
              message: ''
            }
          }, 2000)
        }
      })
    }
  }
}
</script>
