import DesignSystem from '@/views/DesignSystem'

const routes = [
  {
    path: '/live/:id',
    name: 'PublicDocument',
    component: () => import('@/views/PublicDocument')
  },
  {
    path: '/document/:id',
    name: 'Document',
    component: () => import('@/views/Document'),
    meta: {
      requiresAuth: true // @ TODO - Check like this for auth
    }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('@/views/SignUp')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('@/views/ResetPassword')
  },
  {
    path: '/design-system',
    name: 'DesignSystem',
    component: DesignSystem
  },
  {
    path: '/document/loading...',
    component: () => import('@/views/Document')
  },
  {
    path: '*',
    component: () => import('@/views/SignUp')
  }
]

export default routes
