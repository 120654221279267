/*
 This helper will detect the user's browser languange code and loop through all the translation languages we support to identify any matches. If a match is found using includes() then it will return the matched language, if no matches are found, it will return our default language of choice, which is Spanish.
*/

import { translationLanguages } from '@/data/languages'

const detectBrowserLanguage = (defaultLanguage, supportedLanguages) => {
  const browserLanguageCode = navigator.language || navigator.userLanguage

  let language = defaultLanguage || translationLanguages[44] // Spanish

  const languages = supportedLanguages || translationLanguages

  for (const translationLanguage of languages) {
    if (browserLanguageCode.includes(translationLanguage.code))
      language = translationLanguage
  }

  return language
}

export default detectBrowserLanguage
