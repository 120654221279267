const apiBaseUrl = 'http://localhost:3000/api'
const defaultEndPoint = 'entries'
const defaultLanguageCode = 'en-gb'

const findMeaning = (
  word,
  languageCode = defaultLanguageCode,
  endPoint = defaultEndPoint
) => {
  const fetchUrl = `${apiBaseUrl}/${word}/${endPoint}/${languageCode}`

  return fetch(fetchUrl)
    .then(response => {
      return response.json()
    })
    .then(data => {
      return data
    })
}

export { findMeaning }
