import { createPopper } from '@popperjs/core'

const createTooltip = (element, tooltip, settings = {}) => {
  tooltip.innerText = settings.text || 'Tooltip'

  const elementHeight = getComputedStyle(element).height

  if (settings.adapt) tooltip.style.height = elementHeight

  const showEvents = ['mouseenter', 'focus']
  const hideEvents = ['mouseleave', 'blur']

  const show = () => {
    tooltip.setAttribute('data-visible', '')
  }

  const hide = () => {
    tooltip.removeAttribute('data-visible')
  }

  showEvents.forEach(event => {
    element.addEventListener(event, show)
  })

  hideEvents.forEach(event => {
    element.addEventListener(event, hide)
  })

  return createPopper(element, tooltip, {
    placement: settings.position || 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: settings.offset || [0, 4]
        }
      }
    ]
  })
}

export default createTooltip
