<template>
  <transition name="notification">
    <div
      :class="[
        'notification',
        `notification--${type}`,
        { 'notification--auto-width': autoWidth }
      ]"
    >
      <div class="notification__container">
        <i
          v-if="!hideCloseButton"
          @click="$emit('close')"
          class="notification__close-button icon-close"
        ></i>
        <p
          @click="$emit('action')"
          class="notification__message"
          v-html="message"
        ></p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Notification',

  props: {
    message: {
      type: String,
      required: true
    },

    hideCloseButton: {
      type: Boolean,
      required: false
    },

    autoWidth: {
      type: Boolean,
      required: false
    },

    type: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss">
.notification {
  position: fixed;
  bottom: var(--size-unit-2);
  left: 50%;
  transform: translateX(-50%);
  max-width: 400px;
  width: calc(100% - var(--size-unit-4));
  padding: var(--size-unit-2);
  border-radius: var(--size-unit-half);
  background-color: var(--color-brand-darkest);
  color: var(--color-brand-lightest);
  font-size: var(--font-size-s);
  line-height: 20px;
  z-index: 999999999;

  &--auto-width {
    width: auto;
    max-width: initial;

    .notification__message {
      padding: 0 var(--size-unit);
    }
  }

  @include screen-small {
    bottom: calc(#{$editor-footer-height} + var(--size-unit));
  }

  @include screen-medium {
    left: var(--size-unit-3);
    transform: translateX(0);
  }

  &--success {
    background-color: var(--color-success);
    color: var(--color-brand-darkest);

    .notification__message {
      a {
        color: var(--color-brand-darkest) !important;
        text-decoration: underline;
      }
    }
  }

  &--warning {
    background-color: var(--color-warning);
    color: var(--color-brand-darkest);

    .notification__message {
      a {
        color: var(--color-brand-darkest) !important;
        text-decoration: underline;
      }
    }
  }
}

.notification__container {
  position: relative;
  transition: opacity var(--transition-default);
}

.notification__close-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: color var(--transition-default);

  &:hover {
    color: var(--color-main);
  }
}

.notification__message {
  margin: 0;
  padding: 0 var(--size-unit-4) 0 0;
  cursor: default;

  a {
    color: var(--color-main) !important;
    cursor: pointer;
    text-decoration: underline;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="notification" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the notification transition by editing
 * these styles.
 */
.notification-enter {
  transform: scale(1.1);
  opacity: 0;
}

.notification-leave-active {
  transform: scale(1.1);
  opacity: 0;
}
</style>
