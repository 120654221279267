import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

/* Services */
import firebase from '@/services/firebase' // To get $user and $isUserAuthenticated before rendering app

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isUserAuthenticated = firebase.auth().currentUser

  if (requiresAuth && !isUserAuthenticated) {
    next('/signup') // This is used to redirect to signup when not logged in
  } else {
    next()
  }
})

export default router
