export const setGrammar = (value = false) => {
  return window.localStorage.setItem('grammar', value)
}

export const getGrammar = () => {
  const grammarValue = window.localStorage.getItem('grammar')
  const isGrammarActive = grammarValue === 'true'

  return isGrammarActive
}
