<template>
  <label @click.prevent="check" class="container">
    <slot />
    <input type="checkbox" :checked="checkboxState" ref="checkbox" />
    <span :class="['checkmark', { 'checkmark--align-top': alignTop }]"></span>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',

  data: () => ({
    checkboxState: false
  }),

  props: {
    checked: {
      type: Boolean,
      default: false,
      required: false
    },

    alignTop: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  watch: {
    checked() {
      this.checkboxState = this.checked
    }
  },

  mounted() {
    if (this.checked) this.checkboxState = this.checked
  },

  methods: {
    /**
     *
     */
    check(event) {
      this.checkboxState = !this.checkboxState

      this.$emit('checkbox-check', this.checkboxState)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: block;
  position: relative;
  padding-left: var(--size-unit-5);
  margin: var(--size-unit-2) 0;
  cursor: pointer;
  user-select: none;

  &::first-letter {
    text-transform: capitalize;
  }

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    cursor: pointer;

    &:checked {
      ~ {
        .checkmark {
          background-color: var(--color-main);
          border: 1px solid var(--color-main);

          &:after {
            display: block;
          }
        }
      }
    }
  }

  &:hover {
    input {
      ~ {
        .checkmark {
          opacity: var(--opacity-hover);
        }
      }
    }
  }

  .checkmark {
    &:after {
      left: var(--size-unit);
      top: var(--size-unit-half-2);
      width: 6px;
      height: 14px;
      border: solid var(--color-light);
      border-width: 0 2px 2px 0;
      transform: rotate(40deg);
    }
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: var(--size-unit-3);
  width: var(--size-unit-3);
  border: 1px solid var(--color-dark);
  border-radius: var(--radius-default);
  transition: opacity var(--transition-default);

  &--align-top {
    top: 5px;
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
}
</style>
