import readTimeEstimate from 'read-time-estimate'

const readingTimeCalculator = text => {
  const originalResult = readTimeEstimate(text)

  const parsedResult = {
    humanizedDuration: originalResult.humanizedDuration,
    otherLanguageHumanizedDuration: parseNumberToText(
      Math.ceil(originalResult.otherLanguageTime)
    ),
    totalHumanizedDuration: parseNumberToText(
      Math.ceil(originalResult.duration + originalResult.otherLanguageTime)
    )
  }

  return parsedResult
}

const parseNumberToText = number => {
  if (number <= 0) return '0 minutes read'
  else if (number <= 1) return 'less than a minute read'
  else if (number > 2) return `${number} minute read`
  else return `${number} minutes read`
}

export default readingTimeCalculator
