<template>
  <div id="app" :class="['app', { 'dark-mode': getDarkMode }]">
    <main class="app_main">
      <router-view />
    </main>

    <!-- Global notifications -->
    <Notification
      v-show="notification.state && notification.message !== ''"
      :message="notification.message"
      @close="resetNotification"
      :type="notification.type"
    />

    <SaveNotification />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions } from 'vuex'

// Components
import Notification from '@/components/Notification'
import SaveNotification from '@/components/SaveNotification'

export default {
  name: 'App',

  data: () => ({
    notification: {
      type: '',
      state: false,
      message: '',
      autoHide: false
    }
  }),

  components: {
    Notification,
    SaveNotification
  },

  computed: {
    ...mapGetters([
      'getNotification',
      'getAppLanguage',
      'getDarkMode',
      'getIsUserEditingTranslation',
      'getCurrentDocument'
    ])
  },

  watch: {
    getNotification() {
      this.notification = this.getNotification

      if (this.notification.autoHide)
        // Automatically reset notification
        setTimeout(() => {
          this.resetNotification()
        }, 4000)
    },

    getAppLanguage() {
      this.checkForArabicLanguage(this.getAppLanguage)

      return this.getAppLanguage
    },

    $route(to, from) {
      return this.checkForArabicLanguage(this.getAppLanguage)
    }
  },

  mounted() {
    this.initializeAmplitude()
    this.setStripePrices() // Initialize all Stripe prices for SignUp and User Account
    if (this.$user.uid) this.setIsUserSubscribed(this.$user.uid)
    if (this.$getGrammar()) this.initializeLanguageTool()

    window.addEventListener('load', () => {
      this.checkForArabicLanguage(this.getAppLanguage)
    })
  },

  methods: {
    ...mapActions([
      'resetNotification',
      'setStripePrices',
      'setIsUserSubscribed'
    ]),

    checkForArabicLanguage(languageCode) {
      const authBlocks = document.querySelectorAll('.auth')

      authBlocks.forEach(authBlock => {
        authBlock.classList.remove('rtl')
        if (languageCode === 'ar') authBlock.classList.add('rtl')
      })
    },

    /*
     *
     */
    initializeAmplitude() {
      if (this.$amplitudeOn) {
        this.$amplitude.getInstance().setUserId(`${this.$user.email}`)
        this.$amplitude.getInstance().setUserProperties(this.$user)
      }
    },

    /*
     *
     */
    initializeLanguageTool() {
      /* eslint-disable */
      const ltOptions = {
        user: {
          email: 'fluentlyapp@gmail.com',
          token: '99ba28a0d8569410a5100d7a0f746c44'
        },
        apiServerUrl: 'https://api.languagetoolplus.com/v2',
        enableSynonyms: true
      }

      return new LTAssistant(ltOptions)
    }
  }
}
</script>

<style lang="scss" scoped>
.app {
  height: 100vh;
  background-color: var(--color-theme-lightest);
}
</style>
