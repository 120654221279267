var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"button",class:[
    {
      'button--active': _vm.active,
      'button--confirming': _vm.confirming,
      [`button--${_vm.type}`]: _vm.type
    }
  ],style:(`
    margin: ${_vm.buttonMargin};
    padding: ${_vm.buttonPadding};
    width: ${_vm.buttonWidth};
    height: ${_vm.buttonHeight};
  `),attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.clicked.apply(null, arguments)}}},[_c('span',{staticClass:"button__slot"},[_vm._t("default")],2),(_vm.icon)?_c('i',{staticClass:"button__icon",class:`icon-${_vm.icon}`}):_vm._e(),(_vm.confirmationMessage.text)?_c('div',{staticClass:"button__confirmation-message",style:(`color: ${_vm.confirmationMessage.color || ''}`)},[_vm._v(" "+_vm._s(_vm.confirmationMessage.text)+" ")]):_vm._e(),(_vm.tooltip.active)?_c('Tooltip',{ref:"tooltip",attrs:{"tooltipPadding":_vm.tooltip.padding,"tooltipWidth":_vm.tooltip.width,"tooltipHeight":_vm.tooltip.height}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }