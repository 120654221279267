<template>
  <span
    class="tooltip"
    :style="
      `
      padding: ${tooltipPadding};
      width: ${tooltipWidth};
      height: ${tooltipHeight};
    `
    "
    >Tooltip</span
  >
</template>

<script>
export default {
  name: 'Tooltip',

  props: {
    tooltipPadding: {
      type: String,
      required: false
    },

    tooltipWidth: {
      type: String,
      required: false
    },

    tooltipHeight: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  border-radius: var(--radius-default);
  padding: 0 var(--size-unit);
  font-size: var(--font-size-xs);
  background-color: var(--color-brand-darkest);
  color: var(--color-brand-lightest);
  z-index: 999999;

  &[data-visible] {
    opacity: 1;
    visibility: visible;
  }
}
</style>
