import en from './en.json'
import es from './es.json'
import nl from './nl.json'
import ar from './ar.json'

export default {
  en,
  es,
  nl,
  ar
}
