export const setDarkMode = (value = false) => {
  return window.localStorage.setItem('dark-mode', value)
}

export const getDarkMode = () => {
  const darkModeValue = window.localStorage.getItem('dark-mode')
  const isDarkMode = darkModeValue === 'true'

  return isDarkMode
}
