/* Core */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/* Services */
import firebase from '@/services/firebase' // To get $user and $isUserAuthenticated before rendering app
import languageDetector from './services/languageDetector'
import { findMeaning } from './services/dictionaries'
import { stripe } from './services/stripe'

/* Helpers */
import './helpers/vh'
import wordCounter from './helpers/wordCounter'
import readingTimeCalculator from './helpers/readingTimeCalculator'
import detectBrowserLanguage from './helpers/detectBrowserLanguage'

/* Translations */
import languages from '@/languages'

/* Local Storage */
import { setDarkMode, getDarkMode } from './ls/darkMode'
import { setGrammar, getGrammar } from './ls/grammar'

/* Embeded helpers */
import './helpers/keyboardShortcuts'

/* Event bus */
import { EventBus } from './services/eventBus'

/* Amplitude Analytics */
import amplitude from 'amplitude-js'

// Helpers
import { isProductionMode } from './helpers/environment'

/* Directives */
import * as vClickOutside from 'v-click-outside-x' // x is the new version 2020
Vue.use(vClickOutside)

if (isProductionMode) {
  Vue.config.productionTip = false
  amplitude.getInstance().init('d28e9ef95123c74b53ee79260d8b5f90') // FluentlyApp
} else {
  Vue.config.productionTip = true
  amplitude.getInstance().init('fd94867d8c6f74bb922d0dd7c4a19915') // FluentlyBeta
}

/* Globally available services */
Vue.prototype.$findMeaning = findMeaning
Vue.prototype.$languageDetector = languageDetector
Vue.prototype.$amplitude = amplitude
Vue.prototype.$stripe = stripe

/* Globally available helpers */
Vue.prototype.$wordCounter = wordCounter
Vue.prototype.$readingTimeCalculator = readingTimeCalculator
Vue.prototype.$detectBrowserLanguage = detectBrowserLanguage
Vue.prototype.$eventBus = EventBus
Vue.prototype.$currentUrl = window.location.origin
Vue.prototype.$companyName = 'Fluently'
Vue.prototype.$amplitudeOn = isProductionMode
// Vue.prototype.$amplitudeOn = true
Vue.prototype.$setDarkMode = setDarkMode
Vue.prototype.$getDarkMode = getDarkMode
Vue.prototype.$setGrammar = setGrammar
Vue.prototype.$getGrammar = getGrammar
Vue.prototype.$user = {}
Vue.prototype.$languages = languages

firebase.auth().onAuthStateChanged(async rawUserDataFromAuth => {
  if (rawUserDataFromAuth) {
    await firebase
      .firestore()
      .collection('users')
      .where('uid', '==', rawUserDataFromAuth.uid)
      .onSnapshot(() => {
        const user = {}
        user.emailVerified = rawUserDataFromAuth.emailVerified
        user.creationTime = rawUserDataFromAuth.metadata.creationTime
        user.lastSignInTime = rawUserDataFromAuth.metadata.lastSignInTime
        user.isFirstSessionUser =
          rawUserDataFromAuth.metadata.creationTime ===
          rawUserDataFromAuth.metadata.lastSignInTime

        if (!user.uid) user.uid = rawUserDataFromAuth.uid
        if (!user.email) user.email = rawUserDataFromAuth.email
        if (!user.displayName)
          user.displayName = rawUserDataFromAuth.displayName
        if (!user.firstName)
          user.firstName = rawUserDataFromAuth.displayName.split(' ')[0]
        if (!user.lastName)
          user.lastName = rawUserDataFromAuth.displayName.split(' ')[1]
        // @TODO - Remove if not needed any longer
        if (!user.firstDocumentCreated) user.firstDocumentCreated = true
        if (!user.displayLetters)
          user.displayLetters = rawUserDataFromAuth.displayName
            .match(/\b(\w)/g)
            .join('')
            .toUpperCase()

        if (user) {
          /* Globally available data */
          Vue.prototype.$user = user
        }

        return new Vue({
          router,
          store,
          render: h => h(App)
        }).$mount('#app')
      })
  } else {
    return new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
