<template>
  <div class="design-system">
    <h1>{{ $companyName }}'s Design System</h1>
    <a
      href="https://www.figma.com/file/FhKorvV15wUVivI57yWGPD/%F0%9F%A7%AC-Fluently---Design-System?node-id=2555%3A15698"
      >See the Design System in Figma</a
    >
    <h2>Components</h2>

    <section class="design-system__section">
      <h3>Buttons</h3>
      <section>
        <Button>Primary</Button>
        <Button active>Primary active</Button>
        <Button disabled>Primary disabled</Button>
      </section>
      <section>
        <Button buttonPadding="0 8px" buttonHeight="32px">Customized</Button>
        <Button buttonPadding="0 8px" buttonHeight="32px" active
          >Customized active</Button
        >
        <Button buttonPadding="0 8px" buttonHeight="32px" disabled
          >Customized disabled</Button
        >
      </section>
      <section>
        <Button icon="arrow-up">With icon</Button>
        <Button active icon="arrow-up">With icon active</Button>
        <Button disabled icon="arrow-up">With icon disabled</Button>
      </section>
      <section>
        <Button type="secondary">Secondary</Button>
        <Button type="secondary" active>Secondary active</Button>
        <Button type="secondary" disabled>Secondary disabled</Button>
      </section>
      <section>
        <Button type="tertiary">Tertiary</Button>
        <Button type="tertiary" active>Tertiary active</Button>
        <Button type="tertiary" disabled>Tertiary disabled</Button>
      </section>
    </section>

    <section class="design-system__section">
      <h3>Checkbox</h3>
      <section>
        <Checkbox @checkbox-check="getCheckbox1State"
          >Is the checkbox checked? <strong>{{ checkbox1State }}</strong>
        </Checkbox>

        <Checkbox @checkbox-check="getCheckbox2State" checked
          >Is the checkbox checked? <strong>{{ checkbox2State }}</strong>
        </Checkbox>
      </section>
    </section>
  </div>
</template>

<script>
// Components
import Button from '@/components/Button'
import Checkbox from '@/components/Checkbox'

export default {
  name: 'DesignSystem',

  components: {
    Button,
    Checkbox
  },

  data: () => ({
    checkbox1State: false,
    checkbox2State: false
  }),

  methods: {
    getCheckbox1State(checkState) {
      this.checkbox1State = checkState
    },

    getCheckbox2State(checkState) {
      this.checkbox2State = checkState
    }
  }
}
</script>

<style lang="scss" scoped>
.design-system {
  padding: var(--size-unit-5);
  background-color: var(--color-light);
  color: var(--color-dark);

  h1 {
    margin-bottom: var(--size-unit-2);
  }

  h2,
  a {
    margin-bottom: var(--size-unit);
  }

  a {
    display: inline-flex;
  }
}

.design-system__section {
  margin-bottom: var(--size-unit-4);
  padding: var(--size-unit-2);
  border: 1px solid var(--color-dark);
  border-radius: var(--size-unit-half);

  section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    margin-top: var(--size-unit-2);
  }

  h3 {
    margin-bottom: var(--size-unit-2);
  }

  h4 {
    margin-bottom: var(--size-unit);
  }

  div {
    margin-bottom: var(--size-unit-2);
  }
}
</style>
