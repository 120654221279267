const wordCounter = (text = '') => {
  const textLength = Buffer.from(text).length
  const maxTextSizeAllowed = 15000

  if (text === '<br>') text = ''
  text = text.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ') // Removing HTML and spaces

  return {
    charactersNoSpaces: text.replace(/\s+/g, '').length,
    characters: text.length,
    words: text.match(/\w+/g) ? text.match(/\w+/g).length : 0,
    lines: text.split(/\r*\n/).length,
    // The 200 are added as a safty net because the bytes size in AWS seems to be bigger by 200 bytes
    bytes: `${textLength} B`,
    exeded: textLength > maxTextSizeAllowed
  }
}

export default wordCounter
